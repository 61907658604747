import React from 'react'
import styled from 'styled-components'
import { StyledFooterUnderLineItem } from './FooterUnderLine.styles'
import { documents } from '../../../static/db.json'
import { size } from '../../constants'

export const Copyright = styled(({ className }) => (
  <div className={className}>
    <StyledFooterUnderLineItem>
      <span>©Youtalk, 2018 – 2025</span>
    </StyledFooterUnderLineItem>
  </div>
))`
  width: 156px;
  margin-right: 48px;

  @media (max-width: ${size.lg}) {
    width: 280px;
  }

  @media (max-width: ${size.md}) {
    width: 170px;
    margin-right: 16px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const JuristicDocuments = styled(({ className }) => (
  <div className={className}>
    <StyledFooterUnderLineItem>
      <a href={documents.urlPublicOffer} rel="noreferrer" target="_blank">
        Юридические документы
      </a>
    </StyledFooterUnderLineItem>
  </div>
))`
  margin-right: 48px;

  @media (max-width: ${size.md}) {
    margin-right: 16px;
  }

  @media (max-width: ${size.sm}) {
    margin-right: 48px;
    margin-bottom: 24px;
  }
`

export const PaymentsMethods = styled(({ className }) => (
  <div className={className}>
    <StyledFooterUnderLineItem>
      <a href="/sposoby-oplaty/" rel="noreferrer">
        Способы оплаты
      </a>
    </StyledFooterUnderLineItem>
  </div>
))`
  margin-right: 48px;

  @media (max-width: ${size.md}) {
    margin-right: 16px;
  }

  @media (max-width: ${size.sm}) {
    margin-right: 0px;
  }

  @media (max-width: ${size.xs}) {
    margin-bottom: 24px;
  }
`
