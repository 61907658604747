import { AUTH_RESULT } from './organisms/RegistrationModals/vars'
import { gql } from '@apollo/client'

export const baseURL =
  process.env.BACKEND_OVERRIDE_URL ?? `${process.env.GATSBY_SITEURL}/backend`

export function handleErrors(response) {
  if (response.status !== 200) {
    return response.text().then((text) => {
      throw new Error(text)
    })
  } else {
    return response
  }
}

export const SEND_REGISTRATION_REQUEST = gql`
  mutation sendRegistrationRequest($input: SendRegistrationRequestInput!) {
    sendRegistrationRequest(input: $input) {
      ... on AuthErrorResponse {
        code
        message
      }
      ... on SendRegistrationRequestResponseSuccess {
        token
        datetime
        retryAvailableAfter
        bitrixLeadId
      }
    }
  }
`

export const sendFeedback = (data) =>
  fetch(`${baseURL}/api/public/leads/feedback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .catch(handleErrors)
    .then(handleErrors)

export const getLeads = async () => {
  const response = await fetch(`${baseURL}/api/public/leads/count`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  const bitrixResponse = await response.json()
  if (!bitrixResponse) {
    throw new Error(`Failed to get number of leads from Bitrix`)
  }
  return bitrixResponse
}

export const getConfig = async () => {
  const response = await fetch(`${baseURL}/api/public/config`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  const data = await response.json()
  if (!data) {
    throw new Error(`Failed to get public config`)
  }
  return data
}

export const checkPromocodes = async (promocode) => {
  const response = await fetch(
    `${baseURL}/api/public/promocodes/validate?promocodeName=${promocode}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )

  return response
}

export const PublicSubscriptionTypes = {
  FOOTER: 'FOOTER',
  ANXIETY_NOTE: 'ANXIETY_NOTE',
  PANIC_ATTACK_NOTE: 'PANIC_ATTACK_NOTE'
}

export const sendFormDataCarrotQuest = ({ name, phone, email }) => {
  window.carrotquest.track('Отправка заявки на подбор специалиста в битрикс')
  window.carrotquest.identify([
    { op: 'update_or_create', key: '$name', value: name },
    { op: 'update_or_create', key: '$email', value: email },
    { op: 'update_or_create', key: '$phone', value: phone }
  ])
}

export const apiGetToken = () => {
  const registerStorage = window.localStorage.getItem(AUTH_RESULT) ?? '{}'
  return JSON.parse(registerStorage)?.jwt ?? ''
}

export const getCarrotToken = async () => {
  const response = await fetch(`${baseURL}/api/client/carrotToken`, {
    headers: {
      Authorization: `Bearer ${apiGetToken()}`
    },
    method: 'GET'
  })

  return response.text()
}

export const authCarrot = async (userID) => {
  const hash = await getCarrotToken()

  if (window.carrotquest) {
    window.carrotquest.auth(userID, hash)
  }
}
